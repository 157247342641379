import Swiper from "swiper";
import { Navigation, EffectCreative } from "swiper/modules";
import "swiper/css";
import "swiper/css/navigation";

const isDesktopMq = window.matchMedia("(min-width: 768px)");

class GallerySingleImages {
  images: HTMLElement[];
  dialog: HTMLDialogElement | null;
  closeButton: HTMLButtonElement | null;
  swiper?: Swiper;
  constructor() {
    this.images = [
      ...document.querySelectorAll<HTMLElement>("[data-gallery-single]"),
    ];
    this.dialog = document.querySelector<HTMLDialogElement>(
      "[data-dialog-single-images]"
    );
    this.closeButton =
      this.dialog?.querySelector<HTMLButtonElement>("[data-close]") ?? null;

    this.init();
  }

  init() {
    this.initSwiper();
    this.initResize();
    this.initDialog();
  }

  initDialog() {
    this.images.forEach((image) => {
      const toggle = image.querySelector<HTMLButtonElement>("[data-toggle]");
      toggle?.addEventListener("click", (evt) => {
        if (!this.dialog) return;
        if (!this.swiper) return;
        this.dialog.showModal();
        document.querySelector("html")?.classList.add("dialog-open");

        const target = evt.target as HTMLElement;
        const parentContainer = target?.closest(
          ".teaser-illustration"
        ) as HTMLElement | null;
        if (!parentContainer) return;
        const idx = parentContainer.dataset.idx ?? "";
        this.swiper?.slideTo(+idx, 0);
      });

      image.addEventListener("load", () => {
        this.swiper?.update();
      });
    });
    this.closeButton?.addEventListener("click", () => {
      if (!this.dialog) return;
      this.dialog.close();
    });

    this.dialog?.addEventListener("close", () => {
      document.querySelector("html")?.classList.remove("dialog-open");
    });
  }

  initSwiper() {
    if (!isDesktopMq.matches) return;
    const swiperContainer = this.dialog?.querySelector<HTMLElement>(
      "[data-swiper-container]"
    );
    if (!swiperContainer) return;
    this.swiper = new Swiper(swiperContainer, {
      modules: [Navigation, EffectCreative],
      grabCursor: true,
      navigation: {
        enabled: false,
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev",
      },
      slidesPerView: 2,
      centeredSlides: true,
      effect: "creative",
      creativeEffect: {
        prev: {
          translate: ["-77%", 0, 0],
          scale: 0.5,
        },
        next: {
          translate: ["77%", 0, 0],
          scale: 0.5,
        },
      },
      on: {
        click(swiper, event) {
          const target = event.target as HTMLElement | null;
          const slide = target?.closest(".swiper-slide") as HTMLElement | null;
          if (!slide) return;
          swiper.slideTo(+(slide.dataset?.idx ?? ""));
        },
      },
    });
  }

  initResize() {
    window.addEventListener("resize", () => {
      if (!isDesktopMq.matches) {
        this.swiper?.destroy();
        this.swiper = undefined;
        this.dialog?.close();
      } else {
        if (!this.swiper) this.initSwiper();
      }
    });
  }
}

new GallerySingleImages();
