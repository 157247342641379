class QestionaireFilter {
  el?: HTMLElement;
  input?: HTMLInputElement;
  filterables?: HTMLElement[];

  constructor(el: HTMLElement | null) {
    if (!el) return;
    this.el = el;
    const input = this.el.querySelector("input") as HTMLInputElement | null;
    if (!input) return;
    this.input = input;

    this.filterables = [
      ...document.querySelectorAll("[data-questionaires-list] li"),
    ] as HTMLElement[];

    this.init();
  }

  init() {
    this.input!.addEventListener("input", () => this.update());
  }

  update() {
    const val = this.input!.value;

    this.filterables!.forEach((el) => {
      const titleElement = el.querySelector(
        ".questionaires__questionaire-title"
      );
      if (!titleElement) return;

      if (titleElement.textContent?.toLowerCase().includes(val.toLowerCase())) {
        el.classList.remove("hidden");
      } else {
        el.classList.add("hidden");
      }
    });
  }
}

new QestionaireFilter(document.querySelector("[data-questionaire-filter]"));
