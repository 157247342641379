import Swiper from "swiper";
import { Navigation, EffectCreative } from "swiper/modules";
import "swiper/css";
import "swiper/css/navigation";

class Gallery {
  el: HTMLElement;
  toggle: HTMLButtonElement | null;
  dialog: HTMLDialogElement | null;
  swiper?: Swiper;
  constructor(el: HTMLElement) {
    this.el = el;
    this.toggle = this.el.querySelector<HTMLButtonElement>("[data-toggle]");
    this.dialog = this.el.querySelector<HTMLDialogElement>("[data-dialog]");

    this.init();
  }

  init() {
    this.initDialog();
    this.initSwiper();
  }

  initDialog() {
    this.toggle?.addEventListener("click", () => {
      if (!this.dialog) return;
      this.dialog.showModal();
      this.swiper?.update();
      document.querySelector("html")?.classList.add("dialog-open");
    });
    const closeButton =
      this.dialog?.querySelector<HTMLButtonElement>("[data-close]");
    closeButton?.addEventListener("click", () => {
      if (!this.dialog) return;
      this.dialog.close();
    });

    this.dialog?.addEventListener("close", () => {
      document.querySelector("html")?.classList.remove("dialog-open");
    });

    this.dialog?.querySelectorAll("img").forEach((img) => {
      img.addEventListener("load", () => {
        this.swiper?.update();
      });
    });
  }

  initSwiper() {
    const swiperContainer = this.el.querySelector<HTMLElement>(
      "[data-swiper-container]"
    );
    if (!swiperContainer) return;
    this.swiper = new Swiper(swiperContainer, {
      modules: [Navigation, EffectCreative],
      grabCursor: true,
      navigation: {
        enabled: true,
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev",
      },
      slidesPerView: 1,
      centeredSlides: true,
      effect: "creative",
      creativeEffect: {
        prev: {
          translate: ["-77%", 0, 0],
          scale: 0.5,
        },
        next: {
          translate: ["77%", 0, 0],
          scale: 0.5,
        },
      },
      on: {
        afterInit(swiper) {
          requestAnimationFrame(() => swiper.update());
        },
        click(swiper, event) {
          const target = event.target as HTMLElement | null;
          const slide = target?.closest(".swiper-slide") as HTMLElement | null;
          if (!slide) return;
          swiper.slideTo(+(slide.dataset?.idx ?? ""));
        },
      },
      breakpoints: {
        768: {
          slidesPerView: 2,
          navigation: {
            enabled: false,
          },
        },
      },
    });
  }
}

document.querySelectorAll("[data-gallery]").forEach((el) => {
  new Gallery(el as HTMLElement);
});
