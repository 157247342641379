class Menu {
  el?: HTMLElement;
  toggleButton?: HTMLButtonElement | null;
  open: boolean = false;

  constructor(el: HTMLElement) {
    this.el = el;
    this.toggleButton =
      this.el.querySelector<HTMLButtonElement>("[data-menu-toggle]");

    this.onClickBody = this.onClickBody.bind(this);

    this.init();
  }

  init() {
    this.toggleButton?.addEventListener("click", () => {
      this.open = !this.open;
      this.update();
    });

    document.querySelector("body")?.addEventListener("click", this.onClickBody);
  }

  update() {
    this.open
      ? this.el?.classList.add("open")
      : this.el?.classList.remove("open");
  }

  onClickBody(evt: MouseEvent) {
    const target = evt.target as HTMLElement | null;
    if (target?.closest("[data-menu]") === null) {
      this.open = false;
      this.update();
    }
  }
}

document
  .querySelectorAll("[data-menu]")
  .forEach((el) => new Menu(el as HTMLElement));
