class DialogSingle {
  el: HTMLElement;
  toggle: HTMLButtonElement | null;
  dialog: HTMLDialogElement | null;
  constructor(el: HTMLElement) {
    this.el = el;
    this.toggle = this.el.querySelector<HTMLButtonElement>("[data-toggle]");
    this.dialog = this.el.querySelector<HTMLDialogElement>("[data-dialog]");

    this.initDialog();
  }

  initDialog() {
    this.toggle?.addEventListener("click", () => {
      if (!this.dialog) return;
      this.dialog.showModal();
      document.querySelector("html")?.classList.add("dialog-open");
    });

    const closeButton =
      this.dialog?.querySelector<HTMLButtonElement>("[data-close]");
    closeButton?.addEventListener("click", () => {
      if (!this.dialog) return;
      this.dialog.close();
    });

    this.dialog?.addEventListener("close", () => {
      document.querySelector("html")?.classList.remove("dialog-open");
    });
  }
}

document.querySelectorAll("[data-dialog-single]").forEach((el) => {
  new DialogSingle(el as HTMLElement);
});
